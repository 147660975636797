import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Block from "../components/block";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import BackgroundImage from "gatsby-background-image";
import Subfooter from "../components/subfooter";
import SEO from "../components/seo";

function CaseStudyPage({ location, data }) {
  const content = data.caseStudyPage.frontmatter;

  return (
    <Layout
      hero={content.hero}
      location={location}
      subtitle={content.subtitle}
      title={content.title}
    >
      <SEO keywords={[`bloom`, `bloom works`]} title={content.title} />
      {content.upperBlocks.map((block, index) => {
        let contentHTML = remark()
          .use(recommended)
          .use(remarkHtml)
          .processSync(block.content)
          .toString();

        return (
          <Block className={`bloom-bg-white`} key={block.title}>
            <div className={`md:bloom-max-w-2xl md:bloom-mx-auto`}>
              <div className={`bloom-mb-5 bloom-flex`}>
                <h2
                  className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
                >
                  {block.title}
                </h2>
              </div>
              <div
                className={`bloom-prose`}
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />
            </div>
          </Block>
        );
      })}
      {content.quote && content.quote.quote_text !== `` && (
        <div className={`bloom-quotes`}>
          <div
            className={`bloom-max-w-4xl bloom-container bloom-mx-auto md:bloom-py-14`}
          >
            <div
              className={`bloom-bg-alpha-white bloom-quote bloom-w-full bloom-px-6 bloom-py-16`}
            >
              <div
                className={`bloom-text-3xl bloom-leading-tight bloom-text-green bloom-font-bold`}
              >
                {content.quote.quote_text}
              </div>
              {content.quote.attribution && (
                <div
                  className={`bloom-text-xl bloom-quote-attribution bloom-mt-4`}
                >
                  {content.quote.attribution}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {content.lowerBlocks && (
        <Block className={`bloom-bg-white`}>
          <div className="md:bloom-max-w-2xl md:bloom-mx-auto">
            <div className="bloom--mx-2 bloom-flex bloom-flex-wrap">
              {content.lowerBlocks.map((block, index) => {
                let contentHTML = remark()
                  .use(recommended)
                  .use(remarkHtml)
                  .processSync(block.content)
                  .toString();

                return (
                  <Block
                    key={block.title}
                    className={`bloom-w-full md:bloom-w-1/2 bloom-px2 md:bloom-py-0`}
                  >
                    <div className={`md:bloom-max-w-2xl md:bloom-mx-auto`}>
                      <div className={`bloom-mb-5 bloom-flex`}>
                        <h2
                          className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
                        >
                          {block.title}
                        </h2>
                      </div>
                      <div
                        className={`bloom-prose`}
                        dangerouslySetInnerHTML={{ __html: contentHTML }}
                      />
                    </div>
                  </Block>
                );
              })}
            </div>
          </div>
        </Block>
      )}
      {content.projects && (
        <Block>
          <div className={`bloom-mb-5 bloom-flex`}>
            <h2
              className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
            >
              Related Work
            </h2>
          </div>
          <div className={`bloom-flex bloom-flex-wrap bloom--mx-2`}>
            {content.projects.project.map((project, index) => {
              return (
                <div
                  key={project.title}
                  className={`bloom-flex bloom-w-full md:bloom-w-1/2 lg:bloom-w-1/3 bloom-p-2 bloom-mb-4`}
                >
                  <a
                    className={`bloom-card bloom-flex bloom-flex-col bloom-w-full`}
                    href={project.linkURL}
                  >
                    <div
                      className={`bloom-h-56 bloom-w-full bloom-bg-seafoam bloom-mb-2`}
                    >
                      {project.image && (
                        <BackgroundImage
                          backgroundColor={`transparent`}
                          className={`bloom-h-56 bloom-w-full`}
                          fluid={project.image.childImageSharp.fluid}
                          Tag="section"
                        ></BackgroundImage>
                      )}
                    </div>
                    <div className="bloom-p-6">
                      <h3
                        className={`bloom-text-2xl bloom-font-bold bloom-leading-tight bloom-uppercase bloom-mb-1`}
                      >
                        {project.title}
                      </h3>
                      <div
                        className={`bloom-font-bold bloom-text-green bloom-text-lg bloom-leading-tight bloom-mb-2`}
                      >
                        {project.client}
                      </div>
                      <div className={`bloom-prose bloom-mb-2`}>
                        {project.description}
                      </div>
                      <div className={`bloom-link`}>{project.linkTitle}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </Block>
      )}
      <Subfooter></Subfooter>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    caseStudyPage: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        upperBlocks {
          title
          content
        }
        lowerBlocks {
          title
          content
        }
        quote {
          quote_text
          attribution
        }
        projects {
          project {
            image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            client
            description
            title
            linkTitle
            linkURL
          }
        }
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          attributionURL
          attributionText
        }
      }
    }
  }
`;

export default CaseStudyPage;
